;(function () {
	'use strict';

	var animateClassName = 'animate',
		modalClassName = 'open',
		bodyClassName = 'no-scroll',
		i;

	// Removing click delays on touch devices

	FastClick.attach(document.body);

	// Header animation while scrolling

	var sticky = new Sticky('.header', {
		stickyClass: 'is-sticky'
	});

	// Video open/close buttons

	var video = document.getElementById('video'),
		openVideo = document.querySelectorAll('.open-video'),
		closeVideo = document.querySelectorAll('.close-video'),
		videoAnimationTimeout,
		videoAnimationTimeoutDelay = 400;

	for(i = 0; i < openVideo.length; i++) {
		openVideo[i].addEventListener('click', function(e) {
			e.preventDefault();

			if(videoAnimationTimeout) {
				clearTimeout(videoAnimationTimeout);
			}

			if(video.classList) {
				video.classList.add(modalClassName);
			}
			else {
				video.className += ' ' + modalClassName;
			}

			if(document.body.classList) {
				document.body.classList.add(bodyClassName);
			}
			else {
				document.body.className += ' ' + bodyClassName;
			}

			if(video.classList) {
				video.classList.add(animateClassName);
			}
			else {
				video.className += ' ' + animateClassName;
			}
		});
	}

	for(i = 0; i < closeVideo.length; i++) {
		closeVideo[i].addEventListener('click', function(e) {
			e.preventDefault();

			if(videoAnimationTimeout) {
				clearTimeout(videoAnimationTimeout);
			}

			if(video.classList) {
				video.classList.remove(animateClassName);
			}
			else {
				video.className = video.className.replace(new RegExp('(^|\\b)' + animateClassName.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
			}

			videoAnimationTimeout = setTimeout(function() {
				if(video.classList) {
					video.classList.remove(modalClassName);
				}
				else {
					video.className = video.className.replace(new RegExp('(^|\\b)' + modalClassName.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
				}

				if(document.body.classList) {
					document.body.classList.remove(bodyClassName);
				}
				else {
					document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + bodyClassName.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
				}

				clearTimeout(videoAnimationTimeout);
			}, videoAnimationTimeoutDelay);
		});
	}

	// Spec panel open/close buttons

	var spec = document.getElementById('spec'),
		openSpec = document.querySelectorAll('.open-spec'),
		closeSpec = document.querySelectorAll('.close-spec'),
		specAnimationTimeout,
		specAnimationTimeoutDelay = 400;

	for(i = 0; i < openSpec.length; i++) {
		openSpec[i].addEventListener('click', function(e) {
			e.preventDefault();

			if(specAnimationTimeout) {
				clearTimeout(specAnimationTimeout);
			}

			if(spec.classList) {
				spec.classList.add(modalClassName);
			}
			else {
				spec.className += ' ' + modalClassName;
			}

			if(document.body.classList) {
				document.body.classList.add(bodyClassName);
			}
			else {
				document.body.className += ' ' + bodyClassName;
			}

			if(spec.classList) {
				spec.classList.add(animateClassName);
			}
			else {
				spec.className += ' ' + animateClassName;
			}
		});
	}

	for(i = 0; i < closeSpec.length; i++) {
		closeSpec[i].addEventListener('click', function(e) {
			e.preventDefault();

			if(specAnimationTimeout) {
				clearTimeout(specAnimationTimeout);
			}

			if(spec.classList) {
				spec.classList.remove(animateClassName);
			}
			else {
				spec.className = spec.className.replace(new RegExp('(^|\\b)' + animateClassName.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
			}

			specAnimationTimeout = setTimeout(function() {
				if(spec.classList) {
					spec.classList.remove(modalClassName);
				}
				else {
					spec.className = spec.className.replace(new RegExp('(^|\\b)' + modalClassName.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
				}

				if(document.body.classList) {
					document.body.classList.remove(bodyClassName);
				}
				else {
					document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + bodyClassName.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
				}

				clearTimeout(specAnimationTimeout);
			}, specAnimationTimeoutDelay);
		});
	}

	// Scroll to element animations

	var easeFunctions = {
		easeInOutQuad: function (t, b, c, d) {
			t /= d/2;
			if (t < 1) { return c/2*t*t + b; }
			t--;
			return -c/2 * (t*(t-2) - 1) + b;
		}
	};

	var moveTo = new MoveTo({
		tolerance: 0,
		duration: 1200,
		easing: 'easeInOutQuad'
		}, easeFunctions),
		more = document.getElementById('more'),
		back = document.getElementById('back');

	if(more) {
		more.addEventListener('click', function() {
			var target = document.getElementById('start');

			moveTo.move(target);
		});
	}

	if(back) {
		back.addEventListener('click', function() {
			var target = document.getElementById('top');

			moveTo.move(target);
		});
	}

	var hero = document.getElementById('hero');

	if(hero) {
		hero.style.height = window.innerHeight + 'px';
	}

	// Make sure all images are preloaded before showing the site

	imagesLoaded('#home', { background: true }, function() {
		var md = new MobileDetect(window.navigator.userAgent),
			viewFactor;

		if(md.phone()) {
			viewFactor = 0.2;
		}
		else if(md.tablet()) {
			viewFactor = 0.4;
		}
		else {
			viewFactor = 0.6;
		}

		window.sr = ScrollReveal();

		window.sr.reveal('.header', {
			distance: 0,
			duration: 0,
			delay: 800,
			opacity: 0,
			scale: 1,
			easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
			viewFactor: 0
		});

		window.sr.reveal('.home-hero .bg-wrapper', {
			distance: 0,
			duration: 800,
			opacity: 0,
			scale: 1,
			easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
			viewFactor: 0
		});

		window.sr.reveal('.home-hero .content', {
			distance: '10px',
			duration: 1000,
			delay: 400,
			opacity: 0,
			scale: 1,
			easing: 'cubic-bezier(0.39, 0.575, 0.565, 1)'
		});

		window.sr.reveal('.home-hero .more a', {
			origin: 'top',
			distance: '20px',
			duration: 600,
			delay: 1200,
			opacity: 0,
			scale: 1,
			easing: 'cubic-bezier(0.39, 0.575, 0.565, 1)'
		});

		window.sr.reveal('.home-block-1 .border', {
			distance: '20px',
			duration: 800,
			opacity: 0,
			scale: 1,
			easing: 'cubic-bezier(0.39, 0.575, 0.565, 1)',
			viewFactor: viewFactor
		});

		window.sr.reveal('.home-block-1 .graphic', {
			distance: '20px',
			duration: 800,
			opacity: 0,
			scale: 1,
			easing: 'cubic-bezier(0.39, 0.575, 0.565, 1)',
			viewFactor: viewFactor
		});

		window.sr.reveal('.home-block-1 .content-wrapper', {
			distance: '10px',
			duration: 1000,
			delay: 400,
			opacity: 0,
			scale: 1,
			easing: 'cubic-bezier(0.39, 0.575, 0.565, 1)',
			viewFactor: viewFactor
		});

		window.sr.reveal('.home-block-2 .bg-wrapper', {
			distance: 0,
			duration: 800,
			opacity: 0,
			scale: 1,
			easing: 'cubic-bezier(0.39, 0.575, 0.565, 1)',
		});

		window.sr.reveal('.home-block-2 .content', {
			distance: '10px',
			duration: 1000,
			delay: 400,
			opacity: 0,
			scale: 1,
			easing: 'cubic-bezier(0.39, 0.575, 0.565, 1)',
			viewFactor: viewFactor
		});

		window.sr.reveal('.home-block-3 .mosaic-image', {
			distance: '20px',
			duration: 800,
			delay: 400,
			opacity: 0,
			scale: 1,
			easing: 'cubic-bezier(0.39, 0.575, 0.565, 1)',
			viewFactor: viewFactor
		}, 200);

		window.sr.reveal('.home-block-3 .content', {
			distance: '10px',
			duration: 1000,
			delay: 0,
			opacity: 0,
			scale: 1,
			easing: 'cubic-bezier(0.39, 0.575, 0.565, 1)',
			viewFactor: viewFactor
		});

		window.sr.reveal('.home-block-3b .graphic', {
			distance: '20px',
			duration: 800,
			opacity: 0,
			scale: 1,
			easing: 'cubic-bezier(0.39, 0.575, 0.565, 1)',
			viewFactor: viewFactor
		}, 200);

		window.sr.reveal('.home-block-4 .bg-wrapper', {
			distance: 0,
			duration: 800,
			opacity: 0,
			scale: 1,
			easing: 'cubic-bezier(0.39, 0.575, 0.565, 1)'
		});

		window.sr.reveal('.home-block-4 .graphic', {
			distance: '10px',
			duration: 1000,
			delay: 400,
			opacity: 0,
			scale: 1,
			easing: 'cubic-bezier(0.39, 0.575, 0.565, 1)',
			viewFactor: viewFactor
		});

		window.sr.reveal('.home-block-5 .border', {
			distance: '20px',
			duration: 800,
			opacity: 0,
			scale: 1,
			easing: 'cubic-bezier(0.39, 0.575, 0.565, 1)',
			viewFactor: viewFactor
		});

		window.sr.reveal('.home-block-5 .graphic', {
			distance: '20px',
			duration: 800,
			opacity: 0,
			scale: 1,
			easing: 'cubic-bezier(0.39, 0.575, 0.565, 1)',
			viewFactor: viewFactor
		});

		window.sr.reveal('.home-block-5 .content-wrapper', {
			distance: '10px',
			duration: 1000,
			delay: 400,
			opacity: 0,
			scale: 1,
			easing: 'cubic-bezier(0.39, 0.575, 0.565, 1)',
			viewFactor: viewFactor
		});

		window.sr.reveal('.home-block-5b .graphic', {
			distance: '20px',
			duration: 800,
			opacity: 0,
			scale: 1,
			easing: 'cubic-bezier(0.39, 0.575, 0.565, 1)',
			viewFactor: viewFactor
		}, 200);

		window.sr.reveal('.home-block-6 .border', {
			distance: '20px',
			duration: 800,
			opacity: 0,
			scale: 1,
			easing: 'cubic-bezier(0.39, 0.575, 0.565, 1)',
			viewFactor: viewFactor
		});

		window.sr.reveal('.home-block-6 .bg-wrapper', {
			distance: '20px',
			duration: 800,
			opacity: 0,
			scale: 1,
			easing: 'cubic-bezier(0.39, 0.575, 0.565, 1)'
		});

		window.sr.reveal('.home-block-6 .content-wrapper', {
			distance: '10px',
			duration: 1000,
			delay: 400,
			opacity: 0,
			scale: 1,
			easing: 'cubic-bezier(0.39, 0.575, 0.565, 1)',
			viewFactor: viewFactor
		});
	});
}());